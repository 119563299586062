.slider {
    overflow: visible !important;
    display: flex;
    cursor: grab;
    transform: rotate(-2deg);

    &:active {
        cursor: grabbing;
    }

    & > .slide {
        height: auto;
    }
}

.slide {
    overflow: visible !important;
    width: max-content;

    & > .slideLayout {
        height: 100%;
    }
}

.componentImageSlide {
    height: 100px;
    width: max-content;
    
    & > * {
        height: 100%;
    }
}
