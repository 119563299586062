.slideMe {
    display: flex;
    gap: 64px;
    justify-content: center;
    align-items: center;
}

.imageMe {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;

    & > * {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}

.column {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
}

.smallText {
    font-size: 24px;
}

.imageContainer {
    height: 64px;

    & > * { 
        max-height: 100%;
    }
}

.projectsGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
}

.casesGrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    gap: 24px;
}

.videoContainer {
    width: 300px;
    overflow: visible;

    & > * {
        width: 100%;
        height: 175px;
        border-radius: 25px;
    }
}

.bigVideoContainer {
    width: 500px;
    overflow: visible;

    & > * {
        width: 100%;
        height: 288px;
        border-radius: 25px;
    }
}
