.heading,
.subheading,
.text {
    text-align: left;
    font-size: 64px;
}

.subheading {
    font-size: 32px;
}

.centered {
    text-align: center;
}