 @import url(https://fonts.googleapis.com/css?family=Montserrat:700);
 @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,400italic,700italic);

 section.has-light-background,
 section.has-light-background h1,
 section.has-light-background h2,
 section.has-light-background h3,
 section.has-light-background h4,
 section.has-light-background h5,
 section.has-light-background h6 {
   color: #222;
 }
 
 :root {
   --font-family-base: Open Sans, sans-serif;
   --r-heading-font: Montserrat, Impact, sans-serif;
   --font-family-alt: monospace;
   
   --size-20: 20px;

   --r-heading-margin: 0 0 20px 0;
   --r-heading-line-height: 1.2;
   --r-heading-letter-spacing: -0.03em;
   --r-heading-text-transform: none;
   --r-heading-text-shadow: none;
   --r-heading-font-weight: normal;
   --r-heading1-text-shadow: none;

   --font-size-64: 64px;
   --font-size-40: 40px;
   --font-size-32: 32px;
   --font-size-24: 24px;
   --font-size-16: 16px;

   --color-white: #eee;
   --color-blue-900: #00001a;
   --color-pink-500: #ff3399;
   --color-yellow-500: #fcfc47;
   --color-orange-600: #d08a1d;
   --color-orange-500: #e7ad52;
   --color-orange-300: #f3d7ac;
   --r-overlay-element-bg-color: 240, 240, 240;
   --r-overlay-element-fg-color: 0, 0, 0;
 }
 
 .reveal-viewport {
   background: var(--color-blue-900);
 }
 
 .reveal {
   height: 100vh;
   color: var(--color-white);
   text-align: left;
   font-family: var(--font-family-base);
   font-size: var(--font-size-40);
   font-weight: normal;
 }
 
 .reveal ::selection {
   color: var(--color-white);
   background: var(--color-orange-500);
   text-shadow: none;
 }
 
 .reveal ::-moz-selection {
   color: var(--color-white);
   background: var(--color-orange-500);
   text-shadow: none;
 }
 
 .reveal .slides section,
 .reveal .slides section > section {
   line-height: 1.3;
   font-weight: inherit;
 }
 
 /* HEADERS */
 .reveal h1,
 .reveal h2,
 .reveal h3,
 .reveal h4,
 .reveal h5,
 .reveal h6 {
   margin: var(--r-heading-margin);
   color: var(--color-white);
   font-family: var(--r-heading-font);
   font-weight: var(--r-heading-font-weight);
   line-height: var(--r-heading-line-height);
   letter-spacing: var(--r-heading-letter-spacing);
   text-transform: var(--r-heading-text-transform);
   text-shadow: var(--r-heading-text-shadow);
   word-wrap: break-word;
 }
/*  
 .reveal h1 {
   font-size: var(--font-size-64);
 }
 
 .reveal h2 {
   font-size: var(--font-size-32);
 }
 
 .reveal h3 {
   font-size: var(--font-size-24);
 }
 
 .reveal h4 {
   font-size: var(--font-size-16);
 }
  */
 .reveal h1 {
   text-shadow: var(--r-heading1-text-shadow);
 }
 
 /* OTHER */
  .reveal p {
   margin: var(--size-20) 0;
   line-height: 1.3;
 }
 
 /* Remove trailing margins after titles */
 .reveal h1:last-child,
 .reveal h2:last-child,
 .reveal h3:last-child,
 .reveal h4:last-child,
 .reveal h5:last-child,
 .reveal h6:last-child {
   margin-bottom: 0;
 }
 
 /* Ensure certain elements are never larger than the slide itself */
 /* .reveal img,
 .reveal video,
 .reveal iframe {
   max-width: 95%;
   max-height: 95%;
 } */
 
 .reveal strong,
 .reveal b {
   font-weight: bold;
 }
 
 .reveal em {
   font-style: italic;
 }
 
 .reveal ol,
 .reveal dl,
 .reveal ul {
   display: inline-block;
   text-align: left;
   margin: 0 0 0 1em;
 }
 
 .reveal ol {
   list-style-type: decimal;
 }
 
 .reveal ul {
   list-style-type: disc;
 }
 
 .reveal ul ul {
   list-style-type: square;
 }
 
 .reveal ul ul ul {
   list-style-type: circle;
 }
 
 .reveal ul ul,
 .reveal ul ol,
 .reveal ol ol,
 .reveal ol ul {
   display: block;
   margin-left: 40px;
 }
 
 .reveal dt {
   font-weight: bold;
 }
 
 .reveal dd {
   margin-left: 40px;
 }
 
 .reveal blockquote {
   display: block;
   position: relative;
   width: 70%;
   margin: var(--size-20) auto;
   padding: 5px;
   font-style: italic;
   background: rgba(255, 255, 255, 0.05);
   box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
 }
 
 .reveal blockquote p:first-child,
 .reveal blockquote p:last-child {
   display: inline-block;
 }
 
 .reveal q {
   font-style: italic;
 }
 
 .reveal pre {
   display: block;
   position: relative;
   width: 90%;
   margin: var(--size-20) auto;
   text-align: left;
   font-size: 0.55em;
   font-family: var(--font-family-alt);
   line-height: 1.2em;
   word-wrap: break-word;
   box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
 }
 
 .reveal code {
   font-family: var(--font-family-alt);
   text-transform: none;
   tab-size: 2;
 }
 
 .reveal pre code {
   display: block;
   padding: 5px;
   overflow: auto;
   max-height: 400px;
   word-wrap: normal;
 }
 
 .reveal .code-wrapper {
   white-space: normal;
 }
 
 .reveal .code-wrapper code {
   white-space: pre;
 }
 
 .reveal table {
   margin: auto;
   border-collapse: collapse;
   border-spacing: 0;
 }
 
 .reveal table th {
   font-weight: bold;
 }
 
 .reveal table th,
 .reveal table td {
   text-align: left;
   padding: 0.2em 0.5em 0.2em 0.5em;
   border-bottom: 1px solid;
 }
 
 .reveal table th[align=center],
 .reveal table td[align=center] {
   text-align: center;
 }
 
 .reveal table th[align=right],
 .reveal table td[align=right] {
   text-align: right;
 }
 
 .reveal table tbody tr:last-child th,
 .reveal table tbody tr:last-child td {
   border-bottom: none;
 }
 
 .reveal sup {
   vertical-align: super;
   font-size: smaller;
 }
 
 .reveal sub {
   vertical-align: sub;
   font-size: smaller;
 }
 
 .reveal small {
   display: inline-block;
   font-size: 0.6em;
   line-height: 1.2em;
   vertical-align: top;
 }
 
 .reveal small * {
   vertical-align: top;
 }
 
 .reveal img {
   margin: var(--size-20) 0;
 }
 
 /* LINKS */
 .reveal a {
   color: var(--color-pink-500);
   text-decoration: none;
   transition: color 0.15s ease;
 }
 
 .reveal a:hover {
   color: var(--color-orange-300);
   text-shadow: none;
   border: none;
 }
 
 .reveal .roll span:after {
   color: #fff;
   background: var(--color-orange-600);
 }
 
 /*  Frame helper */
 .reveal .r-frame {
   border: 4px solid var(--color-blue-900);
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
 }
 
 .reveal a .r-frame {
   transition: all 0.15s linear;
 }
 
 .reveal a:hover .r-frame {
   border-color: var(--color-pink-500);
   box-shadow: 0 0 20px rgba(0, 0, 0, 0.55);
 }
 
 /* NAVIGATION CONTROLS */
 .reveal .controls {
   color: var(--color-pink-500);
 }
 
 /* PROGRESS BAR */
 .reveal .progress {
   background: rgba(0, 0, 0, 0.2);
   color: var(--color-pink-500);
 }
 
 /* PRINT BACKGROUND */
 @media print {
   .backgrounds {
     background-color: var(--color-white);
   }
 }
 